import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { server } from "@/config/server";
import getHeaders from "./headers";
import { PayRun, PayRunEmployeeCompensation, PayRunSummary } from "@/types/Payroll";
import { ResponsePaginationCursor } from "@/types/Pagination";

export const PayrunsApiSlice = createApi({
  reducerPath: "PayrunsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["Payrun"],
  endpoints: (builder) => ({
    getPayruns: builder.query<ResponsePaginationCursor<PayRun>, any>({
      query: ({ payrollId, previousCursor, nextCursor, itemsPerPage }) => {
        return {
          url: `payrolls/${payrollId}/pay-runs?previousCursor=${previousCursor}&nextCursor=${nextCursor}&itemsPerPage=${itemsPerPage}&page=Next`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Payrun", id: "payruns" }],
    }),
    getPayRunById: builder.query<PayRun, string>({
      query: (payRunId) => {
        return {
          url: `pay-runs/${payRunId}`,
          method: "GET"
        }
      }
    }),
    runPayRun: builder.mutation<any, string>({
      query: (payRunId) => {
        return {
          url: `pay-runs/${payRunId}/re-run`,
          method: "GET"
        }
      }
    }),
    getEmployeeCompensations: builder.query<ResponsePaginationCursor<PayRunEmployeeCompensation>, any>({
      query: ({ payRunId, previousCursor, nextCursor, itemsPerPage, page }) => {
        return {
          url: `pay-runs/${payRunId}/employee-compensations?previousCursor=${previousCursor}&nextCursor=${nextCursor}&itemsPerPage=${itemsPerPage}&page=Next`,
          method: "GET"
        }
      }
    }),
    getPayrollTimeline: builder.query<PayRunSummary[], any>({
      query: ({ payrollId, minPaymentDateExclusive, maxPaymentDateExclusive, numberOfItemsToRetrieve }) => {
        return {
          url: `payrolls/${payrollId}/pay-runs/timeline?minPaymentDateExclusive=${minPaymentDateExclusive}&maxPaymentDateExclusive=${maxPaymentDateExclusive}&numberOfItemsToRetrieve=${numberOfItemsToRetrieve}`,
          method: "GET"
        }
      }
    })
  }),
});

export const {
  useGetPayrunsQuery,
  useGetEmployeeCompensationsQuery,
  useGetPayRunByIdQuery,
  useRunPayRunMutation,
  useGetPayrollTimelineQuery,
  useLazyGetPayrollTimelineQuery
} = PayrunsApiSlice;
