import { Employees } from "@/apis/models/employee.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Employees = {
  Employees: [],
  ActiveEmployees: [],
  InactiveEmployees: []
};

export const companySlice = createSlice({
  name: "employeesList",
  initialState,
  reducers: {
    setEmployees: (state: Employees, action: PayloadAction<any>) => {
      state.Employees = action.payload;
    },
    setActiveEmployees: (state: Employees, action: PayloadAction<any>) => {
      state.ActiveEmployees = action.payload;
    },
    setInactiveEmployees: (state: Employees, action: PayloadAction<any>) => {
      state.InactiveEmployees = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { setEmployees, setActiveEmployees, setInactiveEmployees } = companySlice.actions;

export default companySlice.reducer;
