import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import useStorage from "@/hooks/useStorage";
import { TENANT } from "@/constants/common.constants";
import { server } from "@/config/server";
import getHeaders from "./headers";
import { EmployeeCompensationCalculation, EmployeeCompensationCalculationTarget, Payroll, PayrollSummary } from "@/types/Payroll";
import { buildPaginationQueryString } from "@/utils/pagination";
import { ResponsePaginationCursor } from "@/types/Pagination";

const { getItem } = useStorage();

const tenant_id = JSON.parse(getItem(TENANT));

export const PayrollApiSlice = createApi({
  reducerPath: "PayrollApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["Payroll", "EmployeeCompensations", "Payrun", "PayrollApprovers"],
  endpoints: (builder) => ({
    createPayroll: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/payrolls`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Payroll", id: "payrolls" }],
    }),

    //Allows a tenant to create a domestic payroll
    createDomesticPayroll: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/payrolls`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Payroll", id: "payrolls" }],
    }),
    triggerPayrollProcessing: builder.mutation<any, any>({
      query: (payrollId) => {
        return {
          url: `/payrolls/${payrollId}/trigger-processing`,
          method: "POST",
          body: {}
        };
      },
      invalidatesTags: [{ type: "Payrun", id: "payruns" }],
    }),

    //Allows a tenant to add an employee to a payroll
    addEmployeeToPayroll: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `/payrolls/${data?.payrollId}/employees-to-pay`,
          method: "POST",
          body: [
            {
              id: data?.id,
              payrollEmployeeToPayId: data?.payrollEmployeeToPayId,
              employeeId: data?.employeeId,
              payrollId: data?.payrollId,
              status: data?.status,
              statusAsOfDate: data?.statusAsOfDate,
              approvedBy: data?.approvedBy,
            }
          ],
        };
      },
      invalidatesTags: (result, error, data) => [
        {
          type: "Payroll",
          id: `${data.payrollId}~employee-compensations~${EmployeeCompensationCalculationTarget.EmployeesOnPayroll}`
        },
        {
          type: "Payroll",
          id: `${data.payrollId}~employee-compensations~${EmployeeCompensationCalculationTarget.EmployeesNotOnPayroll}`
        }],
    }),

    // Allows a tenant to list all existing payrolls
    getPayrolls: builder.query<any, any>({
      query: (info) => {
        return {
          url: `companies/${tenant_id}/payrolls?previousCursor=0&nextCursor=1&itemsPerPage=${info.itemsPerPage}&page=Next`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Payroll", id: "payrolls" }],
    }),

    getPayrollDetails: builder.query<Payroll, string>({
      query: (payrollId) => {
        return {
          url: `payrolls/${payrollId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Payroll", id: "payrollId" }],
    }),
    approvePayroll: builder.mutation<any, any>({
      query: (approverBody) => {
        return {
          url: `payrolls/${approverBody.payrollId}/approvers/${approverBody.payrollApproverId}`,
          method: "PUT",
          body: approverBody.form,
        };
      },
      invalidatesTags: [{ type: "Payroll", id: "payrollId" }],
    }),
    calculateEmployeeCompensations: builder.query<ResponsePaginationCursor<EmployeeCompensationCalculation>, any>({
      query: ({ payrollId, target, previousCursor, nextCursor, itemsPerPage, page }) => {
        return {
          url: `payrolls/${payrollId}/calculate-employee-compensations?target=${target}&${buildPaginationQueryString({ previousCursor, nextCursor, itemsPerPage, page })}`,
          method: "GET"
        };
      },
      providesTags: (response, error, args) => [{ type: "EmployeeCompensations", id: `${args.payrollId}~employeeCompensations~${args.target}` }]
    }),
    calculateEmployeeCompensationsByEmployeeIds: builder.mutation<EmployeeCompensationCalculation[], any>({
      query: ({ employeeIds }) => {
        return {
          url: `payrolls/calculate-employee-compensations`,
          method: "POST",
          body: employeeIds
        };
      }
    }),
    updatePayroll: builder.mutation<any, any>({
      query: ({ payrollId, updatedPayroll }) => {
        return {
          url: `payrolls/${payrollId}`,
          method: "PUT",
          body: updatedPayroll
        };
      },
      invalidatesTags: (response, error, args) => [
        { type: "Payroll", id: "payrollId" },
        { type: "EmployeeCompensations", id: `${args.payrollId}~employeeCompensations~${EmployeeCompensationCalculationTarget.EmployeesOnPayroll}` },
        { type: "EmployeeCompensations", id: `${args.payrollId}~employeeCompensations~${EmployeeCompensationCalculationTarget.EmployeesNotOnPayroll}` },
        { type: "PayrollApprovers", id: `${args.payrollId}~payrollApprovers` }
      ]
    }),
    getPayrollSummary: builder.query<PayrollSummary, string>({
      query: (payrollId) => {
        return {
          url: `payrolls/${payrollId}/summary`,
          method: "GET"
        };
      },
      providesTags: [{ type: "Payroll", id: "payrollId" }]
    }),
    getCompanyPayrollSummaries: builder.query<PayrollSummary[], any>({
      query: ({ tenantId, previousCursor, nextCursor, page, itemsPerPage }) => {
        return {
          url: `companies/${tenantId}/payrolls/summary?${buildPaginationQueryString({ previousCursor, nextCursor, page, itemsPerPage })}`,
          method: "GET"
        };
      },
      providesTags: [{ type: "Payroll", id: "payrolls" }]
    }),
    removeEmployeeToPayFromPayroll: builder.mutation<any, any>({
      query: ({ payrollId, employeeToPayId }) => {
        return {
          url: `payrolls/${payrollId}/employees-to-pay/${employeeToPayId}`,
          method: "DELETE"
        };
      },
      invalidatesTags: [{ type: "EmployeeCompensations", id: "employeeCompensations" }]
    }),
  })
});

export const {
  useCreatePayrollMutation,
  useGetPayrollsQuery,
  useGetPayrollDetailsQuery,
  useLazyGetPayrollDetailsQuery,
  useApprovePayrollMutation,
  useAddEmployeeToPayrollMutation,
  useCreateDomesticPayrollMutation,
  useTriggerPayrollProcessingMutation,
  useLazyCalculateEmployeeCompensationsQuery,
  useCalculateEmployeeCompensationsQuery,
  useCalculateEmployeeCompensationsByEmployeeIdsMutation,
  useUpdatePayrollMutation,
  useGetPayrollSummaryQuery,
  useGetCompanyPayrollSummariesQuery,
  useRemoveEmployeeToPayFromPayrollMutation
} = PayrollApiSlice;
