import { server } from "@/config/server";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeaders from "./headers";
import { ResponsePaginationCursor } from "@/types/Pagination";
import { PayrollApprover } from "@/types/Payroll";
import { buildPaginationQueryString } from "@/utils/pagination";

export const payrollApproversApiSlice = createApi({
    reducerPath: "payrollApproversApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders,
    }),
    tagTypes: ["PayrollApprovers"],
    endpoints: (builder) => ({
        getPayrollApprovers: builder.query<ResponsePaginationCursor<PayrollApprover>, any>({
            query: ({ payrollId, previousCursor, nextCursor, itemsPerPage, page }) => {
                return {
                    url: `/payrolls/${payrollId}/approvers?${buildPaginationQueryString({ previousCursor, nextCursor, itemsPerPage, page })}`,
                    method: "GET",
                };
            },
            providesTags: (error, response, args) => [{ type: "PayrollApprovers", id: `${args.payrollId}~payrollApprovers` }],
        }),
        deletePayrollApprover: builder.mutation<any, any>({
            query: ({ payrollId, approverId }) => {
                return {
                    url: `/payrolls/${payrollId}/approvers/${approverId}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ({ response, error, args }) => [{ type: "PayrollApprovers", id: `${args.payrollId}~payrollApprovers` }],
        }),
    })
});

export const {
    useGetPayrollApproversQuery,
    useLazyGetPayrollApproversQuery,
    useDeletePayrollApproverMutation,
} = payrollApproversApiSlice;