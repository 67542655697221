import { marked } from "marked";
import moment from "moment";

/**
 * Convert a local date to UTC DateTime.
 *
 * @param {string} date - The input date in string format.
 * @returns {string} - The converted UTC DateTime in ISO format.
 */
const convertToUTCDateTime = (date) => {
  // Create a new Date object representing the current date and time
  const dateTime = new Date();

  // Get the local time components
  const localHours = dateTime.getHours().toString().padStart(2, "0");
  const localMinutes = dateTime.getMinutes().toString().padStart(2, "0");
  const localSeconds = dateTime.getSeconds().toString().padStart(2, "0");

  // Combine the input date with the local time components to create a local date-time string
  const localDateTimeString = `${moment(date).format("YYYY-MM-DD")}T${localHours}:${localMinutes}:${localSeconds}`;

  // Convert the local date-time string to a UTC ISO date-time string
  const isoDateString = new Date(localDateTimeString).toISOString();

  // Return the converted UTC DateTime
  return isoDateString;
};

export const bytesToSize = (bytes: number): { value: number; unit: string } => {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;

  if (bytes < 1024) {
    return { value: bytes, unit: "B" };
  } else if (kilobytes < 1024) {
    return { value: kilobytes, unit: "KB" };
  } else {
    return { value: megabytes, unit: "MB" };
  }
};

/**
 * Truncates text to `characterLimit` charaters and appends an ellipsis. 
 * @param characterLimit the character limit to which to truncate the text
 * @param text the text to truncate
 * @returns the truncated text if `text` is greater than `characterLimit` otherwise the original text
 */
export const truncateText = (characterLimit: number, text: string): string => {
  if (text.length > characterLimit) {
    return text.substring(0, characterLimit).concat("...");
  }
  return text;
}

export const getOrdinalSuffix = (number: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const v = number % 100;
  return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
}

/**
 * Represents the default date format.
 */
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

/**
 * Converts a markdown text string to an HTML string.
 * @param markdownText the markdown text string to convert.
 * @returns the HTML representation of `markdownText`.
 */
export const convertMarkdownToHtml = (markdownText: string): string => {
  return marked(markdownText, { async: false }).toString()
}

/**
 * Converts a pascal cased string to a normal string. e.g. "PascalCase" to "Pascal case".
 * @param text the pascal cased string to convert.
 * @returns the normal string representation of `text`.
 */
export const pascalToNormal = (text: string): string => {
  return text
    ?.replace(/([A-Z])/g, ' $1')
    ?.trim()
    ?.toLowerCase()
    ?.replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
}

/**
 * Masks all characters of a string except the last few specified by revealCount.
 *
 * @param input - The input string to be masked.
 * @param revealCount - The number of characters to reveal at the end of the string. Defaults to 4.
 * @returns The masked string with only the last revealCount characters visible.
 */
export const maskString = (input: string, revealCount: number = 4): string => {
  if (input.length <= revealCount) {
    return input;
  }
  const maskedPart = input.slice(0, -revealCount).replace(/./g, '*');
  const visiblePart = input.slice(-revealCount);
  return maskedPart + visiblePart;
}

export default convertToUTCDateTime;